import axios from 'axios';
import urls from './urls';
/**
 * get list legal document
 */
const getListLegalDocument = async () => {
  try {
    const res = await axios.get(urls.legalDocumentList);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get list attchement
 * @param {Number} documentSupportId document support id
 */
const getListAttachment = async (documentSupportId) => {
  try {
    const res = await axios.get(`${urls.legalAttachmentList}/${documentSupportId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getListLegalDocument,
  getListAttachment,
};
