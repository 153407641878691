/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const getDetailWorkflowMou = async (mouId) => {
  try {
    const res = await axios.get(`${urls.detailWorkflowMou(mouId)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getOriginLocation = async (filters, { serviceType }) => {
  try {
    const res = await axios.get(`${urls.originMouRoute}/${serviceType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchHistoryWorkFlow = async (workflowId) => {
  try {
    const res = await axios.get(`${urls.contractHistory}/${workflowId}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const approveInProcessMou = async (data) => {
  try {
    const res = await axios.post(`${urls.inboxApproveMou}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const reviseInProcessMou = async (data) => {
  try {
    const res = await axios.post(`${urls.inboxReviseMou}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const rejectInProcessMou = async (data) => {
  try {
    const res = await axios.post(`${urls.inboxRejectMou}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getCooperationInformation = async () => {
  try {
    const res = await axios.get(`${urls.mouRoute}/company`);
    return res?.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getDetailMou = async () => {
  try {
    const res = await axios.get(`${urls.submitMouShipper}/0`);
    return res?.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getListTransportTypeLimit = async (mouId, transportTypeLimitId, filters, source) => {
  try {
    const res = await axios.get(`${urls.submitMouShipper}/${mouId}/transport-type-limit/${transportTypeLimitId}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res?.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const donwloadMou = async (mouId) => {
  try {
    const res = await axios.get(`${urls.submitMouShipper}/${mouId}/download/document`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouLocationByServiceType = async (filters, { mouId, serviceType }) => {
  try {
    const res = await axios.get(`${urls.mouRoute}/${mouId}/route/${serviceType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouRoute = async (filters, {
  mouId, serviceType, transportTypeId, originId,
}) => {
  try {
    if (transportTypeId) filters.transportTypeId = transportTypeId;
    if (originId) filters.originId = originId;
    const res = await axios.get(`${urls.mouRoute}/${mouId}/route/${serviceType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouLocation = async (filters, { mouId }) => {
  try {
    const res = await axios.get(`${urls.mouRoute}/${mouId}/location?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouTransportType = async (filters, { mouId }) => {
  try {
    const res = await axios.get(`${urls.mouRoute}/${mouId}/transport-type?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouTransportTypeLimit = async (filters, { mouId }) => {
  try {
    const res = await axios.get(`${urls.mouRoute}/${mouId}/transport-type-limit?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getOldMouRoute = async (mouId, serviceType, filters) => {
  try {
    const res = await axios.get(
      `${urls.mouRoute}/${mouId}/route/${serviceType}/old?${querystring.stringify(
        filters,
      )}`,
    );
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getListOldTransportTypeLimit = async (mouId, filters) => {
  try {
    const res = await axios.get(`${urls.mouRoute}/${mouId}/transport-type-limit/old?${querystring.stringify(filters)}`);
    return res?.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export {
  getCooperationInformation,
  getOriginLocation,
  getMouRoute,
  getDetailWorkflowMou,
  fetchHistoryWorkFlow,
  approveInProcessMou,
  reviseInProcessMou,
  rejectInProcessMou,
  getDetailMou,
  getListTransportTypeLimit,
  donwloadMou,
  getMouLocation,
  getMouTransportType,
  getMouTransportTypeLimit,
  getMouLocationByServiceType,
  getListOldTransportTypeLimit,
  getOldMouRoute,
};
