import VueViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import Vue from 'vue';
import VuetifyDialog from 'vuetify-dialog';
import Inputmask from 'inputmask';
import * as VueGoogleMaps from 'vue2-google-maps';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import keys from '../keys';
import * as strings from './assets/strings';
import './styles/global.scss';
import * as services from './providers/services';
import PhoneNumberInput from './components/Common/PhoneNumberInput.vue';
import PhoneNumberFormat from './components/Common/PhoneNumberFormat.vue';
import CommonTextInput from './components/Common/CommonTextInput.vue';
import CommonAutocompleteInput from './components/Common/CommonAutocompleteInput.vue';
import CommonDateInput from './components/Common/CommonDateInput.vue';
import DialogViewImage from './components/Common/DialogViewImage.vue';
import DialogTermsConditions from './components/Common/DialogTermsConditions.vue';
import DialogViewPdf from './components/Common/DialogViewPdf.vue';
import CommonError from './components/Common/CommonError.vue';
import CommonAutoCompleteItems from './components/Common/CommonAutoCompleteItems.vue';
import PageNotFound from './components/Common/PageNotFound';
import ViewerImage from './components/Common/ViewerImage.vue';
import DontHavePermissionPage from './components/Common/DontHavePermissionPage.vue';

import 'vuetify-dialog/dist/vuetify-dialog.css';

Vue.directive('mask', {
  bind(el, binding) {
    Inputmask.extendAliases({
      'custom-phone': {
        regex: String.raw`^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$`,
        keepStatic: false,
        greedy: false,
      },
    });
    Inputmask.extendAliases({
      'custom-time': {
        placeholder: '',
        greedy: false,
        regex: '^([0-1][0-9]?|[2][0-3]?|[0-9]):([0-5]?[0-9])?$',
      },
    });
    Inputmask.extendAliases({
      'phone-number': {
        placeholder: '',
        greedy: false,
        regex: '^(\\+62|62)?[\\s-]?0?8[1-9]{1}\\d{1}[\\s-]?\\d{4}[\\s-]?\\d{2,5}$',
      },
    });
    Inputmask(binding.value).mask(el.getElementsByTagName('INPUT')[0]);
  },

});
Vue.prototype.$_strings = strings;
Vue.prototype.$_services = services;
Vue.prototype.$_item_per_page = [5, 10, 25, 50, 100];

Vue.component('PhoneNumberInput', PhoneNumberInput);
Vue.component('PhoneNumberFormat', PhoneNumberFormat);
Vue.component('CommonTextInput', CommonTextInput);
Vue.component('CommonAutocompleteInput', CommonAutocompleteInput);
Vue.component('CommonDateInput', CommonDateInput);
Vue.component('DialogViewImage', DialogViewImage);
Vue.component('DialogTermsConditions', DialogTermsConditions);
Vue.component('DialogViewPdf', DialogViewPdf);
Vue.component('CommonError', CommonError);
Vue.component('CommonAutoCompleteItems', CommonAutoCompleteItems);
Vue.component('PageNotFound', PageNotFound);
Vue.component('ViewerImage', ViewerImage);
Vue.component('DontHavePermissionPage', DontHavePermissionPage);

Vue.mixin({
  computed: {
    userAccess() {
      if (process.env.VUE_APP_API_TARGET === 'development') {
        return {
          canCreate: true,
          canUpdate: true,
          canDelete: true,
        };
      }
      return store.getters['user/userAccess'] || {};
    },
    menuFunctions() {
      return store.getters['user/menuFunctions'] || {};
    },
  },
  methods: {
    capitalizeFirstLetter: (str) => str.charAt(0).toUpperCase() + str.slice(1),
    isEmailValid: (value) => /^[^<>()[\]\\,;:\\%#^\s@\\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}))$/.test(value),
    isPhoneNumberValid: (value) => /^(\+62)\s[1-9]{1}[0-9]{2}(?=.{6,14}$)(-[0-9]{4})+(?:-\d{1,4})$/.test(value),
    // isPhoneNumberValid: (value) => /^(\+62|62)?[\s-]?0?8[1-9]{1}\d{1}[\s-]?\d{4}[\s-]?\d{2,5}$/.test(value),
  },
});
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});
Vue.use(VueViewer);
Vue.config.productionTip = false;

if (process.env.VUE_APP_API_TARGET === 'production') {
  console.log = () => { };
}

Vue.use(VueGoogleMaps, {
  load: {
    key: keys().googleMapsApiKey,
    libraries: 'places',
    region: 'id',
    language: 'id',
  },
});
Vue.use(VuetifyGoogleAutocomplete, {
  vueGoogleMapsCompatibility: true,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
