<template>
  <v-container fluid>
    <v-row class="my-n6">
      <v-col cols="auto" :md="2" class="mt-2">
        <span class="body-2">
          {{inputLabel}}
        </span>
        <h3 v-if="$attrs.rules" class="error--text d-inline">
          *
        </h3>
      </v-col>
      <!-- <v-col cols="auto" class="mt-2">
        :
      </v-col> -->
      <v-col :cols="12" md>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :disabled="disabled"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="displayValue && dateFormat(displayValue)"
              :label="inputLabel"
              required
              :clearable="!disabled"
              solo
              :disabled="disabled"
              outlined
              dense
              flat
              readonly
              v-bind="{...attrs, ...$attrs}"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :disabled="disabled"
            :max="getNewDate"
            v-model="displayValue"
            @input="menu = false"
            locale="id"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import {
  getISODate as _getISODate,
  getUnixTime as _getUnixTime,
  dateFormat,
} from '../../helper/commonHelpers';

export default {
  name: 'CommonDateInput',
  props: {
    inputLabel: String,
    isTextArea: {
      type: Boolean,
      default: false,
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      localVal: '',
    };
  },
  watch: {
    value(val) {
      this.localVal = val;
    },
  },
  computed: {
    displayValue: {
      get() {
        return this.getISODate(this.value);
      },
      set(modifiedValue) {
        this.$emit('input', this.getUnixTime(modifiedValue));
      },
    },
    getNewDate() {
      const currentDate = new Date().toISOString();
      return currentDate;
    },
  },
  methods: {
    dayjs,
    getISODate: (t) => _getISODate(t),
    getUnixTime: (s) => _getUnixTime(s),
    dateFormat,
  },
};
</script>

<style>

</style>
