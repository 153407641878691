/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';
import { skipEmptyStringObject } from '../../helper/commonHelpers';

/**
 * upload image to server and return imageUrl
 * @param {String} base64Image encoded base64 Image string
 * @param {String} fileName file name
 */
const uploadImage = async (base64Image, fileName = '1-1.png', filePath = '', config) => {
  try {
    const data = {
      encodedFile: base64Image,
      fileName,
      filePath,
    };
    const res = await axios.post(urls.uploadImage, data, config);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * upload csv to server
 * @param {FormData} formData csv data
 */
const uploadCsvReason = async (formData, type) => {
  let form;
  for (const pair of formData.entries()) {
    form = pair;
  }
  try {
    const res = await axios.post(
      urls.uploadCsvReason,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * fetch uploaded csv data process status
 * @param {Object} filter list filter
 *  @param {Number} filter.page list page
 *  @param {Number} filter.size list size
 *  @param {String} [filter.sort] list sorting
 *  @param {Type} filter.type list data type
 *  @param {Number} filter.uploadFrom csv uploaded time from
 *  @param {Number} filter.uploadTo csv uploaded time to
 */
const fetchUploadedCsvDataProcessStatus = async (filter, importType) => {
  try {
    const data = skipEmptyStringObject({
      size: filter.itemsPerPage,
      page: filter.page - 1,
      companyId: filter.companyId,
    });
    const res = await axios.get(`${urls.uploadedCsvStatus}/${importType}/filter?${querystring.stringify(data)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * fetch uploaded csv data detail
 * @param {Object} filter list filter
 *  @param {Number} filter.id list id
 *  @param {Number} filter.page list page
 *  @param {Number} filter.size list size
 *  @param {String} [filter.sort] list sorting
 */
const fetchUploadedCsvDataProcessDetail = async (filter, importType) => {
  try {
    const uploadHeaderId = filter.id;
    const data = {
      size: filter.itemsPerPage,
      page: filter.page - 1,
    };
    const res = await axios.get(`${urls.uploadedCsvStatus}/${importType}/detail/${uploadHeaderId}/filter?${querystring.stringify(data)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * fetch data for master data csv upload template
 * @param {String} dataType csv template data type
 */
const fetchCsvTemplateData = async (dataType) => {
  try {
    const res = await axios.get(`${urls.csvTemplateData}?${querystring.stringify({ type: dataType })}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchCsvTemplateDataReason = async (dataType) => {
  try {
    const res = await axios.get(`${urls.csvTemplateDataReason}?type=${dataType}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteFile = async (payload) => {
  try {
    const res = await axios.post(`${urls.deleteFile}`, payload);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const downloadFile = async (url) => {
  window.open(`${process.env.VUE_APP_API_BASE_URL}${urls.uploadImage}?url=${url}`);
};

export {
  uploadImage,
  uploadCsvReason,
  fetchUploadedCsvDataProcessStatus,
  fetchUploadedCsvDataProcessDetail,
  fetchCsvTemplateData,
  fetchCsvTemplateDataReason,
  deleteFile,
  downloadFile,
};
