export default {
  emailDatabase: 'Master Email Invoice',
  nameLabel: 'Nama User',
  emailLabel: 'Email',
  pageName: 'Daftar Email',
  createdBy: 'Dibuat Oleh',
  value: 'Tipe Biaya',
  cancel: 'Batal',
  save: 'Simpan',
  nameFormatLabel: 'Nama User',
  searchText: 'Cari Email',
  add: 'Tambah Email',
  deleteEmailConfirmMsg: 'Apakah Anda yakin ingin menghapus akun email ini?',
  SUCCESS_CREATE_EMAIL_MESSAGE_TEXT: 'Berhasil membuat email baru',
  SUCCESS_EDIT_EMAIL_MESSAGE_TEXT: 'Berhasil mengubah email',
  SUCCESS_DELETE_EMAIL_MESSAGE_TEXT: 'Berhasil menghapus email',
};
