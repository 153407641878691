export default {
  VEHICLE_TITLE: 'Pilih Kendaraan',
  VEHICLE_QUEST: 'Mau kirim dengan jenis kendaraan apa?',
  TYPE_TARIF_QUEST: 'Mau kirim dengan jenis tarif apa?',
  ORDER_TITLE: 'Cek Pesanan',
  REVIEW_ORDER: 'Tinjau Semua Pesanan',
  TYPE_OF_TARIFF: 'JENIS TARIF',
  OVERVIEW_TITLE: 'Overview Semua Pesanan',
  VEHICLE: 'KENDARAAN',
  VEHICLE_TYPE: 'Pilih jenis kendaraan',
  ORIGIN_TITLE: 'Pilih Lokasi',
  ORIGIN_LOC: 'LOKASI ASAL',
  ORIGIN_CONTACT: 'KONTAK PENGIRIM',
  RECIPIENT_CONTACT: 'KONTAK PENERIMA',
  ORIGIN_NAME: 'Nama Pengirim',
  ORIGIN_PHONE: 'No. Telepon Pengirim',
  DESTINATION_TITLE: 'LOKASI TUJUAN AKHIR',
  DESTINATION_LOC: 'LOKASI TUJUAN',
  DESTINATION_CONTACT: 'KONTAK PENERIMA',
  DESTINATION_NAME: 'Nama Penerima',
  DESTINATION_PHONE: 'No. Telepon Penerima',
  ADD_INFO: 'Tambahkan Informasi (opsional)',
  ADD_NOTE: 'CATATAN TAMBAHAN',
  GOODS: 'BARANG YANG DIANTAR',
  GOODS_CATEGORY: 'Kategori Barang',
  GOODS_SUB_CATEGORY: 'Sub Kategori Barang',
  PICKUP_DATE: 'TANGGAL DAN WAKTU PENGAMBILAN BARANG',
  MULTISHIPPER: 'MULTI SHIPPER',
  COMBINE_SHIPPER: 'Gabungkan Dengan Shipper Lain',
  ADD_ORDER: 'Tambah Pesanan',
  UPDATE_ORDER: 'Perbaharui Pesanan',
  CREATE_ORDER_BASP: 'Buat Pesanan Lanjutan',
  SEND_ORDER: 'Pesan Sekarang',
  DUPLICAT_ORDER: 'Duplikasi Pesanan Ini',
  CREATE_CONTINUE_ORDER_BUTTON_LABEL: 'Buat Pesanan Lanjutan',
  CONFIRMATION_DELETE: 'Pesanan akan dihapus secara permanen, apakah anda yakin?',
  SUCCESS_DELETE: 'Berhasil Menghapus Pesanan',
  SELECT_ORDER_NO: 'Pilih No. Pesanan',
  FULL_LOAD: 'Full Load',
  MULTIPICK: 'MULTIPICK',
  MULTIPICK_LOC: 'Lokasi Multipick',
  MULTIPICK_TITLE: 'Pilih Lokasi Multipick',
  MULTIDROP: 'MULTIDROP',
  MULTIDROP_LOC: 'Lokasi Multidrop',
  MULTIDROP_TITLE: 'Pilih Lokasi Multidrop',
  ORDER_NO: 'Nomor Pesanan',
  KUBIKASI: 'KUBIKASI (m3)',
  TONASE: 'TONASE (Kg)',
  KARTON: 'KARTON (Ktn)',
  ETA: 'ETA',
  ETA_NOT_FOUND: 'ETA belum ada',
  BACKHAULING_ORDER: 'Pesananan Backhauling',
  BACKHAULING: 'Backhauling',
  ERROR: {
    EMPTY_CATEGORY: 'Harap Pilih Category',
    EMPTY_SUB_CATEGORY: 'Harap Pilih Sub-Category',
    EMPTY_ORIGIN_CONTACT: 'Kontak pengirim harus diisi',
    EMPTY_DESTINATION_CONTACT: 'Kontak penerima harus diisi',
    EMPTY_REFF_SHIPPER_MERGE: 'Harap Pilih Order dari Shipper Lain',
  },
  LABEL: {
    EDIT: 'Edit',
    DELETE: 'Delete',
  },
  CONFIRM: {
    DELETE: 'Anda yakin akan menghapus pesanan ini?',
  },
  EMPTY_ORDER: 'Belum Ada Pesanan',
  LOAD_MESSAGE: {
    SERVICE_TYPE: 'Sedang memuat jenis tarif',
    TRANSPORT_TYPE: 'Sedang memuat jenis kendaraan',
    LOCATION: 'Sedang memuat lokasi',
    ORIGIN_BACKHAULING: 'Sedang memuat lokasi backhauling',
  },
  STATUS_UPLOAD: 'Status Upload',
};
