<template>
  <v-dialog
    v-model="isOpen"
    hide-overlay
    fullscreen
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        elevation="4"
        light
        color="primary"
      >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="isOpen = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-row>
        <v-col class="d-flex justify-end mr-4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openPdfNewWindow"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </template>
            <span>Print</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="ma-4">
        <v-col class="d-flex align-center">
          <p>{{currentPage}} / {{numPages}}</p>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="page"
            type="number"
            label="Halaman"
            min="1"
            :max="numPages"
          ></v-text-field>
        </v-col>
      </v-row>
      <div
        v-if="loadedRatio > 0 && loadedRatio < 1"
        class="loadedRatio"
        :style="{ width: loadedRatio * 100 + '%' }">
        {{ Math.floor(loadedRatio * 100) }}%
      </div>
      <pdf
        class="pdfStyle"
        :src="urlPdfSelected"
        ref="pdf"
        :page="page"
        :rotate="rotate"
        @error="error"
        @progress="loadedRatio = $event"
        @num-pages="numPages = $event"
        @link-clicked="page = $event"
        @page-loaded="currentPage = $event"
      >
      </pdf>
    </v-card>
  </v-dialog>
</template>

<script>
import pdf from 'vue-pdf';

export default {
  name: 'DialogViewPdf',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    urlPdf: {
      type: String,
      default: '',
    },
  },
  components: {
    pdf,
  },
  data() {
    return {
      src: '',
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0,
      currentPage: 0,
      pageCount: 0,
      isOpen: false,
    };
  },
  watch: {
    isOpen(newVal) {
      if (!newVal) this.$emit('setDialog', 'dialogViewPdf');
    },
    visible(newVal) {
      this.isOpen = newVal;
    },
  },
  computed: {
    urlPdfSelected() {
      return this.urlPdf;
    },
  },
  methods: {
    error(err) {
      return err;
    },
    openPdfNewWindow() {
      window.open(this.urlPdfSelected);
    },
  },
};
</script>

<style scoped lang="scss">
  .loadedRatio {
    background-color: green;
    color: white;
    text-align: center;
  }
  .pdfStyle {
    border: 1px solid black;
    margin: 20px;
  }
</style>
