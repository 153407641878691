<template>
  <div>
    <v-text-field
      v-model="displayValue"
      v-bind="$attrs"
      :class="bindClass"
      :rules="$attrs.disabled ? [] : rules.phoneNumber"
      ref="phoneNumber"
    />
  </div>
</template>

<script>

export default {
  props: {
    value: {},
    required: Boolean,
    bindClass: {
      type: String,
      default: '',
    },
    fax: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.handleFormatValue(this.value);
  },
  data() {
    return {
      rules: {
        phoneNumber: [
          (value) => {
            if (this.$attrs.disabled) return true;
            if (!this.required && !value) return true;
            if (!value) return this.$_strings.messages.validation.REQUIRED(this.fax ? 'Fax' : 'No. Telepon');
            if (value) {
              if (this.isPhoneNumberValid(value)) {
                return true;
              }
              return this.$_strings.messages.validation.PHONE_NUMBER;
            }
            return true;
          },
        ],
      },
      isCreated: false,
    };
  },
  computed: {
    displayValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.handleFormatValue(value);
      },
    },
  },
  watch: {
    value(newVal) {
      if (newVal && (newVal.length > 6 && !this.isCreated)) {
        this.handleFormatValue(newVal);
        this.isCreated = true;
      }
    },
  },
  methods: {
    handleFormatValue(value) {
      let input = `${value}`;
      const inputClear = input.replace(/[\s\D]/g, '');
      if (input === '+62 ') {
        this.$emit('input', '');
        return;
      }
      if (inputClear.length >= 16) {
        const val = value.substring(0, value.length - 1);
        // eslint-disable-next-line no-new-wrappers
        const newString = new String(val);
        this.$emit('input', newString);
        return;
      }
      input = input.replace(/[\s\D]/g, '');
      input = input.replace(/^0|^62/g, '+62 ');
      input = input.replace(/^\d{1}/g, (e) => `+62 ${e}`);
      input = input.replace(/\+62\s\d{3}/g, (e) => `${e}-`);
      input = input.replace(/-\d{5}/g, (e) => `${e.substring(0, e.length - 1)}-${e.substring(e.length - 1)}`);
      input = input.replace(/-\d{5}/g, (e) => `${e.substring(0, e.length - 1)}-${e.substring(e.length - 1)}`);
      let newVal = input;
      if (value && value.match(/[a-zA-Z,./?><;:'"{}[\]\\|*(&^%$#@!`~=-_)]/g)) {
        // eslint-disable-next-line no-new-wrappers
        newVal = new String(input);
      }
      if (value && value.match(/^\+62 0/g)) {
        input = input.replace(/^\+62 0/g, '+62 ');
        // eslint-disable-next-line no-new-wrappers
        newVal = new String(input);
      }
      this.$emit('input', newVal);
    },
  },
};
</script>
