import axios from 'axios';
import urls from './urls';

const myInfo = async function myInfo() {
  try {
    const res = await axios.get(urls.ownUserInfo);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const role = async function role(roleId) {
  try {
    const res = await axios.get(`${urls.role}/${roleId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  myInfo,
  role,
};
