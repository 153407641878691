<template>
  <v-text-field
    v-model="displayValue"
    v-mask="{'alias': 'custom-phone', autoUnmask: true, placeholder: ''}"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  props: {
    value: {},
  },
  data() {
    return {
      localValue: '',
    };
  },
  computed: {
    displayValue: {
      get() {
        return this.value;
      },
      set(modifiedValue) {
        console.log('file: PhoneNumberInput copy.vue ~ line 25 ~ set ~ modifiedValue', modifiedValue);
        this.$emit('input', modifiedValue);
      },
    },
  },
  methods: {
    maskCheck(field) {
      if (field.target.inputmask.isComplete()) {
        console.log('is Complete');
      } else {
        console.log('is Incomplete');
      }
    },
  },
};
</script>

<style>

</style>
