<template>
  <v-autocomplete
    v-model="displayValue"
    v-bind="$attrs"
    :no-data-text="isLoadingMoreData ? '' : 'No data available'"
    :loading="isLoading"
    @focus="onFocus"
    @change="e => $emit('change', e)"
    @input.native="search"
    @input="input"
  >
    <template v-slot:append-item>
      <div
        v-intersect="endIntersect"
      />
      <v-progress-circular
        v-if="isLoadingMoreData"
        :width="2"
        :size="25"
        class="ml-4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </template>
  </v-autocomplete>
</template>

<script>
import axios from 'axios';
import { skipEmptyStringObject } from '@/helper/commonHelpers';

const { CancelToken } = axios;
let source = CancelToken.source();

let setSearch;

export default {
  props: {
    value: {},
    filter: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
    searchName: {
      type: String,
      default: '',
    },
    optional: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    source = CancelToken.source();
    this.setItems(this.$attrs.items);
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  watch: {
    '$attrs.items': {
      handler(newVal) {
        if (!newVal.length) {
          this.items = [];
          this.resultData = 0;
        }
        this.setItems(newVal);
      },
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingMoreData: false,
      tempItems: [],
      items: [],
      oldFilter: {
        page: 0,
        totalData: 0,
      },
      resultData: 0,
    };
  },
  computed: {
    displayValue: {
      get() {
        return this.value;
      },
      set(modifiedValue) {
        this.$emit('input', modifiedValue);
      },
    },
  },
  methods: {
    skipEmptyStringObject,
    setItems(newItems) {
      if (newItems.length && !this.items.length && !this.resultData) {
        this.items = this.$attrs.items.map(() => Math.random());
        this.resultData = this.filter.totalData;
      }
    },
    onFocus() {
      if (!this.items.length && !this.resultData) {
        this.fetchData('isLoadingMoreData');
      }
    },
    endIntersect([{ isIntersecting }]) {
      if (isIntersecting && this.items.length) {
        this.fetchData('isLoadingMoreData');
      }
    },
    resetOldFilter() {
      this.tempItems = [];
      this.oldFilter = {
        page: 0,
        totalData: 0,
      };
    },
    input(event) {
      if (!event) {
        if (this.tempItems.length) this.items = [...this.tempItems];
        this.filter.search = '';
        this.filter.page = this.oldFilter.page;
        this.filter.totalData = this.oldFilter.totalData;
        this.resetOldFilter();
        this.fetchData();
      }
    },
    search(event) {
      this.filter.search = event.target.value;
      if (event.target.value && !this.oldFilter.totalData) {
        this.tempItems = [...this.items];
        this.oldFilter.page = this.filter.page;
        this.oldFilter.totalData = this.filter.totalData;
      }
      if (!event.target.value && this.oldFilter.totalData) {
        this.items = [...this.tempItems];
        this.filter.page = this.oldFilter.page;
        this.filter.totalData = this.oldFilter.totalData;
        this.resetOldFilter();
        this.fetchData();
        return;
      }
      this.filter.page = 0;
      this.filter.totalData = 0;
      clearTimeout(setSearch);
      setSearch = setTimeout(() => this.fetchData(), 1000);
    },
    getServices() {
      switch (this.type) {
        case 'mouLocationByServiceType': return this.$_services.mou.getMouLocationByServiceType;
        case 'mouOriginRoute': return this.$_services.mou.getOriginLocation;
        case 'mouDestinationRoute': return this.$_services.mou.getMouRoute;
        case 'mouLocation': return this.$_services.mou.getMouLocation;
        case 'categoryList': return this.$_services.order.getCategory;
        case 'subCategoryList': return this.$_services.order.getSubCategory;
        case 'reasonCancelOrder': return this.$_services.order.reasonCancelOrder;
        case 'listRoleItems': return this.$_services.userAdmins.roles;
        default: return null;
      }
    },
    canFetchData() {
      const {
        search,
      } = this.filter;
      if (this.items.length && !search && this.resultData) {
        return this.items.length < this.resultData;
      }
      return true;
    },
    async fetchData(loading = 'isLoading') {
      const {
        page,
        size,
        search,
      } = this.filter;
      if (this.canFetchData() && this.getServices()) {
        try {
          this[loading] = true;
          const filters = {
            page,
            size,
            [this.searchName]: search,
          };
          const result = await this.getServices()(this.skipEmptyStringObject(filters), this.optional);
          this.$emit('updateItems', result.data.contents);
          this.items = [...this.items, ...result.data.contents.map(() => Math.random())];
          this.filter.page = page + 1;
          this.filter.totalData = result.data.totalData;
          if (!this.resultData) this.resultData = result.data.totalData;
        } finally {
          this[loading] = false;
        }
      }
    },
  },
};
</script>
