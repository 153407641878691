import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

/**
 * fetch Location list
 * @param {Object} filters
 *  @param {Number} filters.page Page number
 *  @param {Number} filters.size list size
 *  @param {String} filters.name search text
 *  @param {String} filters.sort sorting format: field;ASC|DESC
 */
const fetchLocationList = async ({
  page, size, name, sort,
}) => {
  try {
    const filters = {
      page, size,
    };
    if (name) filters.name = name;
    if (!sort) filters.sort = 'name,asc';
    if (sort) filters.sort = sort;
    const res = await axios.get(`${urls.locationList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * fetch a location's detail
 * @param {Number} id Location Id
 */
const fetchLocationDetail = async (id) => {
  try {
    const res = await axios.get(`${urls.locationDetail}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * create and edit a location detail
 * @param {Object} form location detail form
   * @param {String} form.name
   * @param {String} form.address
   * @param {Number} form.latitude
   * @param {Number} form.longitude
   * @param {Number} form.province province object
   * @param {Number} form.city city object
   * @param {Number} form.district district object
   * @param {Number} form.subDistrict sub district object
   * @param {Number} form.radiusCalculation location reach radius for app
   * @param {Number} form.openingTime location's opening time
   * @param {Number} form.closingTime locations's closing time
   * @param {Number} form.locationNote location's location note ie: "Maksimum yang bisa masuk mobil CDD"
 * @param {Number} [locationId] Location id for edit/update
 */
const createEditLocation = async (form, locationId) => {
  try {
    let res;
    const {
      name,
      address,
      latitude,
      longitude,
      radiusCalculation,
      openingTime,
      closingTime,
      locationNote,
    } = form;
    const data = {
      name,
      address,
      latitude,
      longitude,
      radiusCalculation,
      openingTime,
      closingTime,
      locationNote,
      provinceId: form.province.id,
      cityId: form.city.id,
      districtId: form.district.id,
      subDistrictId: form.subDistrict.id,
    };
    if (locationId) {
      res = await axios.put((`${urls.locationDetail}/${locationId}`), data);
      return res.data;
    }
    res = await axios.post(urls.locationDetail, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get province list
 * @param {String} input province name search
 */
const provinces = async (input) => {
  try {
    const filters = {
      page: 0,
      size: 1000,
      sort: 'name,asc',
    };
    if (input) filters.name = `qlike(${input})`;
    const res = await axios.get(`${urls.provinceList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get city list (kodya)
 * @param {String} input city name search
 * @param {Number} provinceId province id to get cities from
 */
const cities = async (input, provinceId) => {
  try {
    const filters = {
      page: 0,
      size: 1000,
      sort: 'name,asc',
    };
    if (input) filters.name = input;
    const res = await axios.get(`${urls.cityList(provinceId)}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get districts list (kecamatan)
 * @param {String} input districts name search
 * @param {Number} cityId city id to get districts from
 */
const districts = async (input, cityId) => {
  try {
    const filters = {
      page: 0,
      size: 1000,
      cityId,
      sort: 'name,asc',
    };
    if (input) filters.name = input;
    const res = await axios.get(`${urls.districtList(cityId)}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get subDistricts list (kelurahan)
 * @param {String} input subDistricts name search
 * @param {Number} districtId district id to get subDistricts from
 */
const subDistricts = async (input, districtId) => {
  try {
    const filters = {
      page: 0,
      size: 1000,
      districtId,
      sort: 'name,asc',
    };
    if (input) filters.name = input;
    const res = await axios.get(`${urls.subDistrictList(districtId)}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchLocationList,
  fetchLocationDetail,
  createEditLocation,
  provinces,
  cities,
  districts,
  subDistricts,
};
