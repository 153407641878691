const state = {
  fromDate: null,
  toDate: null,
  approval: {
    fromDate: null,
    toDate: null,
    status: '',
  },
};

const getters = {
  fromDate: (_state) => _state.fromDate,
  toDate: (_state) => _state.toDate,
};

const mutations = {
  SET_APPROVAL(store, data) {
    store.approval[data.type] = data.value;
  },
  SET_FROM_DATE(store, data) {
    store.fromDate = data;
  },
  SET_TO_DATE(store, data) {
    store.toDate = data;
  },
};

const actions = {
  setApproval({ commit, dispatch }, data) {
    commit('SET_APPROVAL', data);
  },
  setFromDate({ commit, dispatch }, data) {
    commit('SET_FROM_DATE', data);
  },
  setToDate({ commit, dispatch }, data) {
    commit('SET_TO_DATE', data);
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
