export default {
  APPROVAL_PAGE_NAME: 'Persetujuan',
  COMPLETED: 'Selesai',
  STATUS_APPROVAL: 'Status Persetujuan',
  SHIPPER: 'Pengirim',
  ACCEPTED: 'Menyetujui',
  REVISI: 'Revisi',
  REJECT: 'Menolak',
  INBOX: 'Kotak Masuk',
  INPROCESS: 'Dalam Proses',
  CREATED_AT: 'Pembuat',
  PIC: 'PIC',
};
