export default {
  auth: '/api/v3/auth/shipper/login',
  logout: '/api/v3/auth/logout',
  emailVerification: '/api/v3/shipper/register/validation',
  accountActivation: '/api/v3/shipper/register/activation',
  subAccountActivation: '/api/v3/shipper/register/activation/sub-account',
  ownUserInfo: '/api/v3/auth/whoami',
  role: '/api/v3/auth/role',
  register: '/api/v3/shipper/register',
  decodeToken: '/api/v3/shipper/register/activation',
  setResetPassword: '/api/v3/auth/shipper/reset-password',
  forgotPassword: '/api/v3/auth/user/shipper/reset-password',

  // uploads
  uploadedCsvStatus: '/api/v3/master/upload',
  uploadImage: '/api/v3/file-upload',
  deleteFile: '/api/v3/file-upload/delete',

  // user admin
  userAdminList: '/api/v3/auth/user/filter',
  userAdminCreate: '/api/v3/auth/user',
  userAdminDetail: (companyUserId) => `/api/v3/auth/user/${companyUserId}`,
  userAdminReset: (companyUserId) => `/api/v3/auth/user/shipper/reset-password/${companyUserId}`,
  userAdminResend: (companyUserId) => `/api/v3/auth/user/${companyUserId}/resend-activation`,
  userAdminDelete: (companyUserId) => `/api/v3/auth/user/${companyUserId}`,
  roleList: '/api/v3/auth/role/filter?appType=web_shipper',

  // order
  managementShipment: '/api/v3/management/shipment',
  getShipment: '/api/v3/orders/shipments',
  getCategory: '/api/v3/master/category/filter',
  getServiceType: '/api/v3/shipper/mou/service',
  getTransportType: (type) => `/api/v3/shipper/mou/service/${type}/transport-type`,
  getRoute: (type, transportId) => `/api/v3/shipper/mou/service/${type}/transport-type/${transportId}/route`,
  getSubCategory: (idCategories) => `/api/v3/master/category/${idCategories}/sub/filter`,
  createShipment: '/api/v3/shipper/shipment',
  statusOrder: '/api/v3/shipper/shipment/filter',
  getMultishipper: '/api/v3/shipper/shipment/multi',
  getBackhauling: (id) => `/api/v3/shipper/mou/backhauling/${id}`,
  shipmentSummary: '/api/v3/shipper/shipment/summary',
  shipment: 'api/v3/shipper/shipment',
  getReason: 'api/v3/master/reason',
  shipmentDocument: (shipmentId) => `/api/v3/shipper/shipment/${shipmentId}/document`,
  orderList: '/api/v3/shipper/shipment',
  orderTransaction: '/api/v3/shipper/transaction',
  saveDriverChange: '/api/v3/shipper/shipment/assign/driver',
  waitingShipment: '/api/v3/shipper/shipment/show-sign',

  // locations
  locationList: '/api/v3/master/location/filter',
  locationDetail: '/api/v3/master/location',
  provinceList: '/api/v3/master/location/province/filter',
  cityList: (provinceId) => `/api/v3/master/location/province/${provinceId}/city/filter`,
  districtList: (cityId) => `/api/v3/master/location/city/${cityId}/district/filter`,
  subDistrictList: (districtId) => `/api/v3/master/location/district/${districtId}/sub-district/filter`,

  // registration
  createWorkflow: '/api/v3/shipper/company/register/create',
  companyRegistrationDraft: '/api/v3/shipper/company/register/draft',
  companyDocumentSupport: '/api/v3/shipper/company/register/document',
  submitCompanyInformation: '/api/v3/shipper/company/register',
  saveAttachment: (companyId) => `/api/v3/shipper/company/register/document/${companyId}/attachment`,

  // Legal Documents
  legalDocumentList: '/api/v3/management/company/register/legal',
  legalAttachmentList: '/api/v3/management/company/register/legal/attachment',

  // Approval
  listInboxApproval: '/api/v3/workflow/page/inbox',
  listCompletedApproval: '/api/v3/workflow/page/completed',
  listApprovalInProcess: '/api/v3/workflow/page/progress',
  listHistoryApproval: '/api/v3/workflow/history',
  shipperRegistrationApprove: '/api/v3/shipper/company/register/action',
  actionWorkflow: '/api/v3/contract/company/workflow',
  getWorkflow: '/api/v3/shipper/company/register',
  reviseWorkflow: (workflowId, workflowDetailId) => `/api/v3/shipper/company/register/revise/${workflowId}/${workflowDetailId}`,

  // mou
  listMouShipper: '/api/v3/shipper/mou/filter',
  listMouTransporter: '/api/v3/transporter/mou/filter',
  submitMouShipper: '/api/v3/shipper/mou',
  submitMouTransporter: '/api/v3/transporter/mou',
  detailMouShipper: (mouId) => `/api/v3/shipper/mou/${mouId}`,
  detailMouTransporter: (mouId) => `/api/v3/transporter/mou/${mouId}`,
  detailWorkflowMou: (mouId) => `/api/v3/mou/workflow/${mouId}`,
  inboxApproveMou: '/api/v3/mou/workflow/action/approve',
  inboxRejectMou: '/api/v3/mou/workflow/action/reject',
  inboxReviseMou: '/api/v3/mou/workflow/action/revise',
  mouRoute: '/api/v3/mou/shipper',
  originMouRoute: '/api/v3/mou/shipper/company/route',

  // contract
  contractHistory: '/api/v3/workflow/history',

  // parameter
  parameterList: '/api/v3/master/parameters',
  parameterGroup: '/api/v3/master/parameters/filter',
  selectionInfo: '/api/v3/master/reason',

  // Notificatioin
  notification: '/notification/v1/notifications/web',

  // Eta
  etaList: '/api/v3/master/eta/',

  // AccountInfo
  getAccountInfo: (companyUserId) => `/api/v3/user/${companyUserId}/profile`,
  templateNotifications: (roleId) => `/notification/v1/templates/role/${roleId}`,
  notificationAllowed: (companyUserId) => `/api/v3/user/notifications/${companyUserId}`,

  // master
  master: '/api/v3/master',

  // REPORTS
  reports: '/reports',
  // getTypeReports: '/reports/type/SHIPPER',
  // getReportById: '/reports',
  // getExportReport: '/reports/export/CSV',
  // getReport: '/reports',

  // Wording
  fetchDetailByCodeWording: '/api/v3/master/parameters/wording/code',

  // Email
  emailList: '/api/v3/master/email-customer',
  emailDetail: (emailId) => `/api/v3/master/email-customer/${emailId}`,

  // contract
  contractDetail: '/api/v3/contract/company',
  contractCompanyList: '/api/v3/master/company/filter',
};
