const SEARCH_LOCATION_INPUT_PLACEHOLDER = 'Cari nama lokasi';

const LOCATION_NAME_COMMON = 'Nama lokasi';
const ADDRESS_COMMON = 'Alamat';
const PROVINCE_COMMON = 'Provinsi';
const CITY_COMMON = 'Kodya / Kab';
const DISTRICT_COMMON = 'Kecamatan';
const SUBDISTRICT_COMMON = 'Kelurahan';
const POSTAL_CODE = 'Kode Pos';

const LOCATION_NAME_INPUT_LABEL = LOCATION_NAME_COMMON;
const ADDRESS_INPUT_LABEL = ADDRESS_COMMON;
const POSITION_INPUT_LABEL = 'Posisi';
const LATITUDE_INPUT_LABEL = 'Latitude';
const LONGITUDE_INPUT_LABEL = 'Longitude';
const RADIUS_INPUT_LABEL = 'Radius';
const PROVINCE_INPUT_LABEL = PROVINCE_COMMON;
const CITY_INPUT_LABEL = CITY_COMMON;
const DISTRICT_INPUT_LABEL = DISTRICT_COMMON;
const SUBDISTRICT_INPUT_LABEL = SUBDISTRICT_COMMON;
const OPENING_TIME_INPUT_LABEL = 'Jam Buka';
const CLOSING_TIME_INPUT_LABEL = 'Jam Tutup';
const LOCATION_NOTE_INPUT_LABEL = 'Catatan Lokasi';
const POSTAL_CODE_INPUT_LABEL = POSTAL_CODE;

export default {
  SEARCH_LOCATION_INPUT_PLACEHOLDER,

  LOCATION_NAME_INPUT_LABEL,
  ADDRESS_INPUT_LABEL,
  POSITION_INPUT_LABEL,
  LATITUDE_INPUT_LABEL,
  LONGITUDE_INPUT_LABEL,
  RADIUS_INPUT_LABEL,
  PROVINCE_INPUT_LABEL,
  CITY_INPUT_LABEL,
  DISTRICT_INPUT_LABEL,
  SUBDISTRICT_INPUT_LABEL,
  OPENING_TIME_INPUT_LABEL,
  CLOSING_TIME_INPUT_LABEL,
  LOCATION_NOTE_INPUT_LABEL,
  POSTAL_CODE_INPUT_LABEL,
};
