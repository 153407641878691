<template>
  <v-dialog
    v-model="isOpen"
    max-width="800"
    scrollable
    ref="dialog"
  >
    <v-card class="overflow-hidden">
      <v-row>
        <v-col class="d-flex justify-end">
          <v-toolbar-items>
            <v-btn
              icon
              color="black"
              dark
              @click="isOpen = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-col>
      </v-row>
      <v-card-text
        class="justify-center d-flex"
        @mouseenter="handleHover"
        @mouseleave="handleHover"
      >
        <v-img
          contain
          max-height="750"
          max-width="750"
          :src="imageSelected"
          :style="canDownload && hover ? 'opacity:.5' : ''"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <div
          v-if="hover && canDownload"
          class="text-center wrapper-download"
        >
          <v-icon
            x-large
            @click="onButtonDownload"
          >
            mdi-download
          </v-icon>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogViewImage',
  data() {
    return {
      isOpen: false,
      hover: false,
    };
  },
  props: {
    canDownload: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    imageUrl: {
      type: String,
      default: '',
    },
  },
  watch: {
    isOpen(newVal) {
      if (!newVal) this.$emit('setDialog', 'dialogViewImage');
    },
    visible(newVal) {
      this.isOpen = newVal;
    },
  },
  computed: {
    imageSelected() {
      return this.imageUrl;
    },
  },
  methods: {
    handleHover() {
      this.hover = !this.hover;
    },
    onButtonDownload() {
      this.$_services.uploads.downloadFile(this.imageUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
  .wrapper-download {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
  }
</style>
