const TAB_NAME = 'Dokumen Pendukung';

const NO_TABLE_HEADER_LABEL = 'No';
const NAME_TABLE_HEADER_LABEL = 'Nama Dokumen Pendukung';
const MANDATORY_TABLE_HEADER_LABEL = 'Wajib Dilengkapi';
const DESCRIPTION_TABLE_HEADER_LABEL = 'Keterangan';
const ACTION_TABLE_HEADER_LABEL = 'Aksi';

export default {
  TAB_NAME,
  NO_TABLE_HEADER_LABEL,
  NAME_TABLE_HEADER_LABEL,
  MANDATORY_TABLE_HEADER_LABEL,
  DESCRIPTION_TABLE_HEADER_LABEL,
  ACTION_TABLE_HEADER_LABEL,
};
