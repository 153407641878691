// import roleAccess from '../../assets/strings/roleAccess';
import Vue from 'vue';

const state = {
  myUserInfo: null,
  fcmToken: null,
  isLoading: false,
  inboxUnread: 0,
  userAccess: null,
  menuFunctions: [],
  countAuthGuard: 0,
};

const getters = {
  role: (_state) => _state.myUserInfo && _state.myUserInfo.roleId,
  // isAllowed: (_state, _getters) => (action) => !!(roleAccess[_getters.role] && roleAccess[_getters.role].includes(action)),
  isActivated: (_state) => _state.myUserInfo.isActive,
  isLoading: (_state) => _state.isLoading,
  inboxUnread: (_state) => _state.inboxUnread,
  userAccess: (_state) => _state.userAccess,
  menuFunctions: (_state) => _state.menuFunctions.reduce((acc, s) => {
    acc[s.accessMenu] = true;
    acc.reports = s.reports || [];
    return acc;
  }, {}),
  countAuthGuard: (_state) => _state.countAuthGuard,
};

const mutations = {
  set_my_user_info(store, data) {
    store.myUserInfo = data;
  },
  reset_my_user_info(store) {
    store.myUserInfo = null;
  },
  set_fcm_token(store, data) {
    store.fcmToken = data;
  },
  set_loading(store, data) {
    store.isLoading = data;
  },
  set_inbox_unread(store, data) {
    store.inboxUnread = data;
  },
  set_crud_access(store, data) {
    store.userAccess = data;
  },
  set_menu_functions(store, data) {
    store.menuFunctions = data;
  },
  set_increment_count_auth_guard(store, data) {
    store.countAuthGuard = data;
  },
};

const ROLE_ACCESS = ['SHIPPER_SUPER_ADMIN', 'SHIPPER_ADMIN', 'SHIPPER_MONITORING', 'SHIPPER_ADMIN_FAA', 'SHIPPER_GUEST', 'MONITORING', 'SHIPPER_GUEST2'];

const actions = {
  async fetchMyInfo({ commit, getters: _getters }) {
    // const loadingInstance = this._vm.$loading({ fullscreen: true });
    commit('set_loading', true);
    try {
      const res = await Vue.prototype.$_services.user.myInfo();
      const data = {
        ...res.data,
        rolesName: res.data.roleName.split('_').join().replaceAll(',', ' '),
      };
      commit('set_my_user_info', data);
      commit('set_loading', false);

      // let allowedRoles = ['SUPER_ADMIN', 'ADMIN', 'MONITORING']
      // if (allowedRoles.indexOf(res.data.role) > -1) {
      if ((!_getters.isActivated && _getters.isActivated !== undefined)
      || (!ROLE_ACCESS.includes(res.data.roleName))) {
        // loadingInstance.close();
        commit('auth/logout', null, { root: true });
        throw new Error('not allowed');
      }
      return res;
      // if (_getters.isAllowed('WEB_ACCESS')) {
      //   loadingInstance.close();
      //   return res;
      // }
      // loadingInstance.close();
      // commit('auth/logout', null, { root: true });
      // VAlert.prototype.$dialog.notify.error('Anda tidak punya akses untuk membuka halaman ini');
      // throw new Error('not allowed');
    } catch (error) {
      // loadingInstance.close();
      commit('auth/logout', null, { root: true });
      return Promise.reject(error);
    }
  },

  async fetchFcmToken({ commit }) {
    try {
      const fcmToken = await Vue.prototype.$_services.notifications.fetchFcmToken();
      if (fcmToken) {
        commit('set_fcm_token', fcmToken);
        return fcmToken;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  setIncrementCountAuthGuard({ commit }, data) {
    commit('set_increment_count_auth_guard', data);
  },
  setInboxUnread({ commit, dispatch }, data) {
    commit('set_inbox_unread', data);
  },
  setMyUserInfo({ commit, dispatch }, data) {
    commit('set_my_user_info', data);
  },
  setCrudAccess({ commit, dispatch }, data) {
    commit('set_crud_access', data);
  },
  setMenuFunctions({ commit, dispatch }, data) {
    commit('set_menu_functions', data);
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
