export default {
  BACK: 'Kembali',
  EXIT: 'Keluar',
  NEXT: 'Selanjutnya',
  YES: 'Ya',
  NO: 'Tidak',
  CANCEL: 'Batal',
  EDIT: 'Ubah',
  PROCESS: 'Proses',
  SAVE: 'Simpan',
  DELETE: 'Hapus',
  CREATED_BY: 'Dibuat Oleh',
  UPDATED_BY: 'Diubah Oleh',
  WARNING: 'Peringatan!',
  STATUS: 'Status',
  SUBMIT: 'Submit',
  SAVE_AS_DRAFT: 'Simpan Draft',
  NO_DATA: 'Tidak ada data',
  NUMBER: 'No',
  DATE: 'Tanggal',
  NOTE: 'Catatan',
  ACTION: 'Aksi',
  VIEW_DETAIL: 'Lihat Detail',
  DETAIL: 'Detail',
  NAME: 'Nama',
  FILTER: 'Filter',
  CLOSE: 'Tutup',
  ERROR_MESSAGE: 'Terjadi Kesalahan',
  DOWNLOAD: 'Download',
  SEARCH: 'Cari',
  APPLY: 'Terapkan',
  commonTablePagination: (page, length, size, totalEntry) => {
    if (length >= 1) {
      if (page === 1 && totalEntry <= size) {
        return (`1 - ${length} dari ${totalEntry}`);
      }
      if (page === 1) {
        return (`1 - ${size} dari ${totalEntry}`);
      }
      if (page >= 2) {
        const from = (page - 1) * size + 1;
        const to = from + (length - 1);
        return (`${from} - ${to} dari ${totalEntry}`);
      }
    }
    return '';
  },
};
