/* eslint-disable import/no-cycle */
import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const getTransportType = async (type) => {
  try {
    const res = await axios.get(urls.getTransportType(type));
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const getRoute = async (serviceType, transportId) => {
  try {
    const res = await axios.get(urls.getRoute(serviceType, transportId));
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const getShipment = async (orderId) => {
  try {
    const res = await axios.get(`${urls.getShipment}/${orderId}`);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const getData = async () => {
  try {
    const filterPage = { page: 0, size: 1000 };
    const getCategory = await axios.get(`${urls.getCategory}?${querystring.stringify(filterPage)}`);
    const getServiceType = await axios.get(urls.getServiceType);
    const result = await Promise.all([getServiceType, getCategory]);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getServiceType = async () => {
  try {
    const res = await axios.get(urls.getServiceType);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getCategory = async (filters) => {
  try {
    const res = await axios.get(`${urls.getCategory}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getSubCategory = async (filters, { categoryId }) => {
  try {
    const res = await axios.get(`${urls.getSubCategory(categoryId)}?${querystring.stringify(filters)}`);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const createShipments = async (statusDatetimeTz, shipments) => {
  try {
    const form = {
      statusDatetimeTz,
      shipments,
    };
    const res = await axios.post(urls.createShipment, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const createOrderBasp = async (statusDatetimeTz, shipments, id) => {
  try {
    const form = {
      statusDatetimeTz,
      shipments,
    };
    const res = await axios.post(`${urls.createShipment}/${id}/continuation`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getMultishipper = async (filter) => {
  try {
    const res = await axios.get(`${urls.getMultishipper}?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getRouteBackhauling = async (id) => {
  try {
    const res = await axios.get(`${urls.getBackhauling(id)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getShipmentSummary = async (source) => {
  try {
    const res = await axios.get(urls.shipmentSummary, {
      cancelToken: source.token,
    });
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getStatusOrder = async (filter, status, source) => {
  try {
    const res = await axios.get(`${urls.statusOrder}/${status}?${querystring.stringify(filter)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getOrderById = async (orderId, isBasp, source) => {
  try {
    const res = await axios.get(`${urls.shipment}/${orderId}${isBasp ? '/basp' : ''}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getHistoryStatus = async (orderId) => {
  try {
    const res = await axios.get(`${urls.shipment}/${orderId}/status`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const baspConfirmation = async (orderId, data) => {
  try {
    const res = await axios.post(`${urls.shipment}/${orderId}/basp/confirmation`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const cancelOrder = async (id, form) => {
  try {
    const res = await axios.patch(`${urls.shipment}/${id}/status/canceled`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const baspDetail = async (orderId, data) => {
  try {
    const res = await axios.get(`${urls.shipment}/${orderId}/basp`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const reasonCancelOrder = async (filters) => {
  const { name } = filters;
  try {
    filters.sort = 'name,asc';
    filters.type = 'qIn(cancellation)';
    filters.isActive = 'qistrue';
    if (name) filters.name = `qlike(${name})`;
    const res = await axios.get(`${urls.getReason}/filter/?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchDocument = async (shipmentId) => {
  try {
    const res = await axios.get(urls.shipmentDocument(shipmentId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const orderList = async (filter, status, search, source) => {
  try {
    if (search) {
      filter.title = `${search}`;
    } else {
      delete filter.title;
    }
    const res = await axios.get(`${urls.orderList}/filter/${status}?${querystring.stringify(filter)}`, {
      cancelToken: source.token,
    });
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const orderDocument = async (orderId, source) => {
  try {
    const res = await axios.get(`${urls.orderList}/${orderId}/document`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getOrderDetail = async (filters, status, title, orderId, isBasp, source) => {
  try {
    const getList = orderList(filters, status, title, source);
    const getDetail = getOrderById(orderId, isBasp, source);
    const getStatus = getHistoryStatus(orderId, source);
    const getDocument = orderDocument(orderId, source);
    const result = await Promise.all([getList, getDetail, getStatus, getDocument]);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

const uploadOrders = async ({ shipmentId, formData }) => {
  try {
    const res = await axios.post(`${urls.managementShipment}/detail-item/upload/${shipmentId}`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDetailOrderUploaded = async ({ shipmentId, filter }) => {
  try {
    const res = await axios.get(`${urls.managementShipment}/detail-item/filter/${shipmentId}?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchCsvTemplateData = async () => {
  try {
    const res = await axios.get(`${urls.managementShipment}/template`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const UploadShipmentCSV = async ({ formData }) => {
  try {
    const res = await axios.post(`${urls.createShipment}/file-upload/upload`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const templateShipmentCSV = async () => {
  try {
    const res = await axios.get(`${urls.createShipment}/file-upload/template`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getServiceType,
  getCategory,
  getTransportType,
  getRoute,
  getShipment,
  getData,
  getSubCategory,
  createShipments,
  createOrderBasp,
  getMultishipper,
  getRouteBackhauling,
  getShipmentSummary,
  getStatusOrder,
  getOrderById,
  getHistoryStatus,
  baspConfirmation,
  cancelOrder,
  baspDetail,
  reasonCancelOrder,
  fetchDocument,
  orderList,
  orderDocument,
  getOrderDetail,
  uploadOrders,
  getDetailOrderUploaded,
  fetchCsvTemplateData,
  UploadShipmentCSV,
  templateShipmentCSV,
};
