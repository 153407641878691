<template>
  <v-app>
    <router-view></router-view>
    <loading ref="loading" />
  </v-app>
</template>

<script>
import Inputmask from 'inputmask';
import loading from './components/Common/loading';
import { setupNotification } from './helper/fcm';

export default {
  name: 'App',

  components: {
    loading,
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
  },
  watch: {
    currentPath() {
      this.setInputMask();
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.$root.$loading = this.$refs.loading;
    this.setupNotification();
    this.setInputMask();
  },
  updated() {
    this.setInputMask();
  },
  methods: {
    async setupNotification() {
      const resultSetupNotification = await setupNotification();
      if (resultSetupNotification) {
        this.$store.dispatch('user/fetchFcmToken');
      }
    },
    setInputMask() {
      const inputElement = document.getElementsByTagName('INPUT');
      const textAreaElement = document.getElementsByTagName('textarea');
      inputElement.forEach((el) => el.addEventListener('focus', (event) => {
        const label = el.previousSibling?.innerHTML?.toLowerCase();
        if (event.srcElement.inputmask) return;
        if (label === 'email' || label === 'kata sandi') {
          Inputmask.remove(el);
          return;
        }
        const im = new Inputmask({ regex: '^[^-\\s].*$', placeholder: '' });
        im.mask(el);
      }));
      inputElement.forEach((el) => el.addEventListener('input', (event) => {
        const label = el.previousSibling?.innerHTML?.toLowerCase();
        if (event.srcElement.inputmask) return;
        if (label === 'email' || label === 'kata sandi') {
          Inputmask.remove(el);
          return;
        }
        const im = new Inputmask({ regex: '^[^-\\s].*$', placeholder: '' });
        im.mask(el);
      }));
      inputElement.forEach((el) => el.addEventListener('paste', (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        if (pastedData && pastedData.trim()) {
          Inputmask.remove(el);
        }
      }));
      textAreaElement.forEach((el) => el.addEventListener('focus', () => {
        const im = new Inputmask({ regex: '^[^-\\s][a-zA-Z0-9_\\s-]+$', placeholder: '' });
        im.mask(el);
      }));
      textAreaElement.forEach((el) => el.addEventListener('input', (event) => {
        if (!event.target.value) {
          const im = new Inputmask({ regex: '^[^-\\s].*$', placeholder: '' });
          im.mask(el);
          return;
        }
        Inputmask.remove(el);
      }));
      textAreaElement.forEach((el) => el.addEventListener('paste', (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        if (pastedData && pastedData.trim()) {
          Inputmask.remove(el);
        }
      }));
    },
  },
};
</script>

<style scoped lang="scss">
.v-footer {
  position: absolute;
  width: 300px;
  margin-top: 10px;
  bottom: 0;
  display: flex;
  justify-content: center;
  background: none;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
</style>
