const PAGE_NAME = 'Dokumen Legal';

const NO_TABLE_HEADER_LABEL = 'No';
const NAME_TABLE_HEADER_LABEL = 'Nama Dokumen Pendukung';
const MANDATORY_TABLE_HEADER_LABEL = 'Wajib Dilengkapi';
const DESCRIPTION_TABLE_HEADER_LABEL = 'Keterangan';
const ACTION_TABLE_HEADER_LABEL = 'Aksi';

const AGREEMENT_LINK_LABEL = 'Persyaratan Perjanjian';

export default {
  PAGE_NAME,
  NO_TABLE_HEADER_LABEL,
  NAME_TABLE_HEADER_LABEL,
  MANDATORY_TABLE_HEADER_LABEL,
  DESCRIPTION_TABLE_HEADER_LABEL,
  ACTION_TABLE_HEADER_LABEL,
  AGREEMENT_LINK_LABEL,
};
