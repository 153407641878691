<template>
  <v-overlay :value="visible">
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  created() {
    this.visible = this.isLoading;
  },
  computed: {
    isLoading() {
      return this.$store.getters['loading/isLoading'];
    },
  },
  watch: {
    isLoading(newVal, oldVal) {
      this.visible = newVal;
    },
  },
  data: () => ({
    visible: false,
  }),
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
