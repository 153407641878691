import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const getListInbox = async (filters) => {
  try {
    const res = await axios.get(`${urls.listInboxApproval}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListCompletedApproval = async (filters) => {
  try {
    const res = await axios.get(`${urls.listCompletedApproval}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListApprovalInProcesss = async (filters) => {
  try {
    const res = await axios.get(`${urls.listApprovalInProcess}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListHistoryApproval = async (workflowId) => {
  try {
    const res = await axios.get(`${urls.listHistoryApproval}/${workflowId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const actionWorkflow = async (data, typeApprove) => {
  try {
    const res = await axios.post(`${urls.actionWorkflow}/${typeApprove}`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getWorkflow = async (id) => {
  try {
    const res = await axios.get(`${urls.getWorkflow}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDocumentSupport = async (workflowId) => {
  try {
    const res = await axios.get(`${urls.getWorkflow}/document/${workflowId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getStatusRegisterCompany = async () => {
  try {
    const res = await axios.get(`${urls.getWorkflow}/status`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const setIsRead = async (workflowId) => {
  try {
    const res = await axios.patch(`${urls.listInboxApproval}/${workflowId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getInboxUnread = async () => {
  try {
    const res = await axios.get(`${urls.listInboxApproval}/unread`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getListInbox,
  getListCompletedApproval,
  getListApprovalInProcesss,
  getListHistoryApproval,
  actionWorkflow,
  getWorkflow,
  getStatusRegisterCompany,
  getDocumentSupport,
  setIsRead,
  getInboxUnread,
};
