/* eslint-disable import/no-cycle */
import axios from 'axios';
import interceptorSetup from './interceptors';

import * as auth from './auth';
import * as user from './user';
import * as account from './account';
import * as userAdmins from './userAdmins';
import * as order from './order';
import * as locations from './locations';
import * as registration from './registration';
import * as uploads from './uploads';
import * as legalDocument from './legalDocument';
import * as approval from './approval';
import * as mou from './mou';
import * as masterData from './masterData';
import * as notifications from './notifications';
import * as eta from './eta';
import * as reports from './reports';
import * as wording from './wording';
import * as email from './email';
import * as contract from './contract';

if (process.env.VUE_APP_API_TARGET === 'mock') {
  console.log('TCL: services index VUE_APP_API_TARGET', process.env.VUE_APP_API_TARGET);
  // eslint-disable-next-line global-require
  require(/* webpackChunkName: "mock-services" */ '../mock-services'); // import mock adapter if env is mock
}

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
interceptorSetup();

export {
  auth,
  user,
  account,
  userAdmins,
  order,
  locations,
  registration,
  uploads,
  legalDocument,
  approval,
  mou,
  masterData,
  notifications,
  eta,
  reports,
  wording,
  email,
  contract,
};
