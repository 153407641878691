/* eslint-disable import/no-cycle */
import axios from 'axios';
import urls from './urls';
import store from '../../store';

const getCompanyInformationDraft = async () => {
  try {
    const res = await axios.get(urls.companyRegistrationDraft);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const submitCompanyInfo = async (data) => {
  try {
    const res = await axios.post(urls.createWorkflow, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const reviseCompanyRegistration = async (form, workflowId, workflowDetailId) => {
  try {
    const res = await axios.post(urls.reviseWorkflow(workflowId, workflowDetailId), form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const reviseDocumentSupport = async (form, workflowId, workflowDetailId) => {
  try {
    const res = await axios.post(`${urls.submitCompanyInformation}/document/revise/${workflowId}/${workflowDetailId}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const uploadDocumentLegal = async (form, config) => {
  try {
    const res = await axios.post(`${urls.submitCompanyInformation}/document/revise/upload`, form, config);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteDocumentLegal = async (documentId, workflowId) => {
  try {
    const res = await axios.delete(`${urls.submitCompanyInformation}/document/revise/${workflowId}/upload/${documentId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDocumentSupport = async () => {
  try {
    const res = await axios.get(urls.companyDocumentSupport);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveAttachment = async (data) => {
  try {
    const { companyId } = store.state.user.myUserInfo;
    const res = await axios.post(urls.saveAttachment(companyId), data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getZipCode = async (provinceName, cityName, districtName, subDistrictName) => {
  try {
    const res = await axios.get(`${urls.master}/zipcode/${provinceName}/${cityName}/${districtName}/${subDistrictName}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getCompanyInformationDraft,
  submitCompanyInfo,
  getDocumentSupport,
  saveAttachment,
  reviseCompanyRegistration,
  deleteDocumentLegal,
  uploadDocumentLegal,
  reviseDocumentSupport,
  getZipCode,
};
