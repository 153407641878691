const PAGE_NAME = 'Pengaturan User Admin';
const DB_NAME = 'User Admin';
const CREATE_USERADMIN_PAGE_NAME = 'Buat user admin baru';
const EDIT_USERADMIN_PAGE_NAME = 'Ubah user admin';

const CREATE_USERADMIN_BUTTON_LABEL = 'Tambah user admin';
const SEARCH_USERADMIN_INPUT_PLACEHOLDER = 'Cari nama user admin';

const NAME_TABLE_HEADER_LABEL = 'Nama';
const NAME_ACCOUNT_HEADER_LABEL = 'Nama Perusahaan';
const EMAIL_TABLE_HEADER_LABEL = 'Email';
const ROLE_TABLE_HEADER_LABEL = 'Role';
const STATUS_TABLE_HEADER_LABEL = 'Status';

const RESETPASSWORD_BTN_LABEL = 'Reset Password';
const ACTIVATED_OPTION_LABEL = 'Aktif';
const INACTIVATED_OPTION_LABEL = 'Tidak Aktif';

const PHONE_INPUT_LABEL = 'No Telepon';
const EMAIL_INPUT_LABEL = 'Email';
const ROLE_INPUT_LABEL = 'Role';
const IMAGE_INPUT_LABEL = 'Gambar';

const DELETE_USER_CONFIRM_MESSAGE = 'Apakah anda yakin ingin menghapus user ini?';
const SUCCESS_CREATE_USER_MESSAGE_TEXT = 'Data user berhasil dibuat, silahkan cek email untuk proses aktivasi.';
const SUCCESS_EDIT_USER_MESSAGE_TEXT = 'Data user berhasil diubah';
const STATUS_USER_ADMIN = 'Semua Status';
const SUCCESS_RESET_USER_MESSAGE_TEXT = 'Password berhasil direset';
const SUCCESS_RESET_PASSWORD_USER_MESSAGE_TEXT = 'Silahkan cek kotak masuk email untuk melanjutkan proses reset password';
const SUCCESS_RESEND_USER_MESSAGE_TEXT = 'Sistem telah mengirimkan kembali email untuk aktivasi';
const SUCCESS_DELETE_USER_MESSAGE_TEXT = 'Data user berhasil dihapus';

export default {
  PAGE_NAME,
  CREATE_USERADMIN_PAGE_NAME,
  EDIT_USERADMIN_PAGE_NAME,
  CREATE_USERADMIN_BUTTON_LABEL,
  SEARCH_USERADMIN_INPUT_PLACEHOLDER,
  NAME_TABLE_HEADER_LABEL,
  EMAIL_TABLE_HEADER_LABEL,
  ROLE_TABLE_HEADER_LABEL,
  STATUS_TABLE_HEADER_LABEL,
  RESETPASSWORD_BTN_LABEL,
  ACTIVATED_OPTION_LABEL,
  INACTIVATED_OPTION_LABEL,
  PHONE_INPUT_LABEL,
  EMAIL_INPUT_LABEL,
  ROLE_INPUT_LABEL,
  IMAGE_INPUT_LABEL,
  DELETE_USER_CONFIRM_MESSAGE,
  SUCCESS_CREATE_USER_MESSAGE_TEXT,
  SUCCESS_EDIT_USER_MESSAGE_TEXT,
  STATUS_USER_ADMIN,
  DB_NAME,
  SUCCESS_RESET_USER_MESSAGE_TEXT,
  SUCCESS_RESEND_USER_MESSAGE_TEXT,
  SUCCESS_DELETE_USER_MESSAGE_TEXT,
  NAME_ACCOUNT_HEADER_LABEL,
  SUCCESS_RESET_PASSWORD_USER_MESSAGE_TEXT,
};
