import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const etaList = async ({
  page, sort, size, name, nameDestination,
}) => {
  try {
    const filter = {
      page, size,
    };
    if (name) {
      filter.originalLocation = name;
    } else {
      delete filter.originalLocation;
    }
    if (nameDestination) filter.destinationLocation = nameDestination;
    if (sort) {
      filter.sort = sort;
    } else {
      filter.sort = 'createdAt,desc';
    }
    const res = await axios.get(
      `${urls.etaList}?${querystring.stringify(filter)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  etaList,
};
