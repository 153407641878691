import axios from 'axios';
import urls from './urls';
// eslint-disable-next-line import/no-cycle
import store from '../../store';

const login = async function login({ email, password }) {
  try {
    const data = {
      email,
      password,
      fcm: store.state.user.fcmToken,
    };
    const res = await axios.post(urls.auth, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const logout = async function logout() {
  try {
    const res = await axios.delete(urls.logout);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const register = async function register(email, phoneNo) {
  try {
    const req = {
      email,
      phoneNo,
    };

    const formData = new FormData();
    formData.append('email', req.email);
    formData.append('phoneNo', req.phoneNo);
    const res = await axios.post(
      urls.register,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  login,
  logout,
  register,
};
