<template>
  <viewer>
    <img
      :style="{
        objectFit: 'cover',
        width: '100%',
        cursor: 'pointer'
      }"
      :src="src"
      :height="height"
    >
  </viewer>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
};
</script>
<style>
  ul>li:nth-child(5), li:nth-child(7) {
    display: none !important;
  }
</style>
