import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const aboutInfo = async (data) => {
  try {
    const res = await axios.get(`${urls.fetchDetailByCodeWording}/WOR_TENTANG_KAMI`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const contactInfo = async (data) => {
  try {
    const res = await axios.get(`${urls.fetchDetailByCodeWording}/WOR_HUBUNGI_KAMI`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const termsInfo = async (data) => {
  try {
    const res = await axios.get(`${urls.fetchDetailByCodeWording}/WOR_SYARAT_DAN_KETENTUAN`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const privacyPolicy = async (data) => {
  try {
    const res = await axios.get(`${urls.fetchDetailByCodeWording}/WOR_KEBIJAKAN_PRIVASI`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  aboutInfo,
  contactInfo,
  termsInfo,
  privacyPolicy,
};
