export default () => {
  switch (process.env.VUE_APP_API_TARGET) {
    case 'mock':
      return {
        googleMapsApiKey: 'AIzaSyDreVHfvUheZewXbZwHuw5p7zvICyA4NF0',
        fcmPublicVapidKey: 'BIF6Tv8W-nkEJ4xEl0F9zFjsw6TjROIqU011ivcwMx0ONTxWu6Rj5n6NMgTM2EzMpMeZfCl0sudUAikGnq-Cpy0',
        fcmSenderId: '205952350480',
      };
      // return {
      //   googleMapsApiKey: 'AIzaSyDnV8UqQ59_YBJLeyK4tdhkUJbGuSj89Nw',
      //   fcmPublicVapidKey: 'BFt4OHMlFpiHSpRuEnsq0BUKTSaUZAByVEGOsGz39Y5JlMLxmkWqLwURJ4a2hS9JzKBnWGO6HLw_qx4RvkeQCwA',
      //   fcmSenderId: '426849266713',
      // };
    case 'development':
    case 'sandbox':
      return {
        googleMapsApiKey: 'AIzaSyDreVHfvUheZewXbZwHuw5p7zvICyA4NF0',
        fcmPublicVapidKey: 'BIF6Tv8W-nkEJ4xEl0F9zFjsw6TjROIqU011ivcwMx0ONTxWu6Rj5n6NMgTM2EzMpMeZfCl0sudUAikGnq-Cpy0',
        fcmSenderId: '205952350480',
      };
      // return {
      //   googleMapsApiKey: 'AIzaSyDnV8UqQ59_YBJLeyK4tdhkUJbGuSj89Nw',
      //   fcmPublicVapidKey: 'BFt4OHMlFpiHSpRuEnsq0BUKTSaUZAByVEGOsGz39Y5JlMLxmkWqLwURJ4a2hS9JzKBnWGO6HLw_qx4RvkeQCwA',
      //   fcmSenderId: '426849266713',
      // };
    case 'staging':
      return {
        googleMapsApiKey: 'AIzaSyDreVHfvUheZewXbZwHuw5p7zvICyA4NF0',
        fcmPublicVapidKey: 'BIF6Tv8W-nkEJ4xEl0F9zFjsw6TjROIqU011ivcwMx0ONTxWu6Rj5n6NMgTM2EzMpMeZfCl0sudUAikGnq-Cpy0',
        fcmSenderId: '205952350480',
      };
    case 'production':
      return {
        googleMapsApiKey: 'AIzaSyDreVHfvUheZewXbZwHuw5p7zvICyA4NF0',
        fcmPublicVapidKey: 'BIF6Tv8W-nkEJ4xEl0F9zFjsw6TjROIqU011ivcwMx0ONTxWu6Rj5n6NMgTM2EzMpMeZfCl0sudUAikGnq-Cpy0',
        fcmSenderId: '205952350480',
      };
    default:
      break;
  }
};
