export default {
  PAGE_NAME: 'Pesanan',
  NEED_CONFIRMATION: 'Butuh Konfirmasi',
  IN_PROCESS: 'Dalam Proses',
  COMPLETED: 'Selesai',
  INPUT_ORDER_NO: 'Masukkan nomor pesanan',
  STATUS: 'Status',
  PICKUP_DATE: 'Tanggal Pengambilan Barang',
  ADDITIONAL_INFORMATION: 'Informasi Tambahan',
  TYPE_VEHICLE: 'Tipe Kendaraan',
  EXPIRED: 'Kedaluwarsa',
  VIEW: 'Lihat',
  DRIVER_NAME: 'Nama Sopir',
  VEHICLE_PLATE: 'Nomor Kendaraan',
  DRIVER_CONTACT: 'Kontak Sopir',
  ORIGIN: 'Lokasi Asal',
  BASP_LOCATION: 'Lokasi BASP',
  BASP_NOTE: 'Catatan BASP',
  BASP_TITLE: 'Alasan BASP',
  CANCEL_ORDER: 'Batalkan Pesanan',
  SENDER_NAME: 'Nama Pengirim',
  DESTINATION: 'Lokasi Tujuan',
  CUSTOMER_NAME: 'Nama Penerima',
  CATEGORY: 'Kategori Barang',
  SUB_CATEGORY: 'Sub Kategori Barang',
  SENDER_NOTE: 'Catatan Pengiriman',
  REASON_CANCELLATION: 'Alasan Pembatalan',
  CONTACT_NUMBER: 'Nomor Telepon',
  STATUS_ORDER: 'Status Pesanan',
  STATUS_HISTORY: 'Status History',
  RETURN_NOTE: 'Catatan Pengembalian',
  RECIPIENT_NOTE: 'Catatan Penerimaan',
  RECEIVED_WAREHOUSE: 'Barang diterima Gudang',
  RETURN_ITEM: 'Barang Kembali, Buat Pesanan Baru',
  SUCCESS: 'Berhasil',
  SUCCESS_RETURN_ITEM: 'Barang Berhasil Diterima Gudang',
  LOCATION_MULTIPICK: 'Lokasi Multipick',
  LOCATION_MULTIDROP: 'Lokasi Multidrop',
  MULTISHIPPER: 'Multi Shipper',
  NO_RUTE: 'Rute tidak ditemukan silahkan hubungi admin fastlog',
  END_OF_ORDER: 'Anda berada di akhir pesanan',
  PLEASE_CHOOSE_A_REASON: 'Silahkan pilih alasan pembatalan anda',
  GOODS_NOTE: 'Catatan Barang',
  ADDITIONAL_NOTE: 'Informasi tambahan',
  NAME_SENDER: 'Nama Pengirim',
  PHONE_NUMBER_SENDER: 'No Telepon Pengirim',
  RECEPIENTS_NAME: 'Nama Penerima',
  PHONE_NO_RECIPIENT: 'No Telepon Penerima',
  MASSA: 'Tonase(kg)',
  CUBIC: 'Kubikasi(m3)',
  BOX_CARTON: 'Karton(ktn)',
  TONNAGE: 'Tonase',
};
