const PAGE_NAME = 'Persetujuan';
const REGISTRATION_PAGE = 'Registrasi';
const HISTORY_APPROVAL = 'Riwayat Persetujuan';
const COMPANY_REGISTRATION = 'Registrasi Perusahaan';

export default {
  PAGE_NAME,
  REGISTRATION_PAGE,
  HISTORY_APPROVAL,
  COMPANY_REGISTRATION,
};
