<template>
  <v-dialog
    v-model="isOpen"
    max-width="1200"
    scrollable
  >
    <v-card>
      <v-row class="ma-0 pa-0">
        <v-col class="d-flex justify-end">
          <v-toolbar-items>
            <v-btn
              icon
              color="black"
              dark
              @click="setDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-col>
      </v-row>
      <v-card-title class="text-h5 lighten-2 ml-3">
        Syarat dan Ketentuan
      </v-card-title>
      <v-card-text v-if="isLoading">
        <v-skeleton-loader
          type="
          list-item-three-line,
          list-item-three-line,
          list-item-three-line,
          list-item-three-line,
          list-item-three-line
          "
        ></v-skeleton-loader>
      </v-card-text>
      <v-card-text class="dialogSNK" v-else>
        <vue-editor disabled v-model="form.parameterValue"></vue-editor>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="setDialog"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  components: {
    VueEditor,
  },
  name: 'DialogViewImage',
  data() {
    return {
      isLoading: false,
      form: {
        parameterValue: '',
      },
    };
  },
  watch: {
    visible(newVal) {
      if (newVal && !this.form.parameterValue) {
        this.fetchData();
      }
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isOpen() {
      return this.visible;
    },
  },
  methods: {
    setDialog() {
      this.$emit('setDialog', 'dialogTermsConditions');
    },
    fetchData() {
      this.isLoading = true;
      this.$_services.wording.termsInfo()
        .then((res) => {
          this.form = res.data;
        }).finally((e) => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ql-toolbar {
    display: none;
  }
  .ql-container.ql-snow{
    border: none !important;
  }
  p {
    margin-bottom: 0px !important;
  }
}
.dialogSNK {
  font-size: 15px;
}
</style>
